<template>
  <svg
    width="24px"
    height="21px"
    viewBox="0 0 24 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        transform="translate(-103.000000, -471.000000)"
        fill="currentColor"
        fill-rule="nonzero"
      >
        <g transform="translate(103.000000, 471.000000)">
          <path
            id="Shape"
            :d="`
              M0,20.9032258 L24,20.9032258 L12,0 L0,20.9032258 Z
              M13.0909091,17.6027165 L10.9090909,17.6027165 L10.9090909,15.4023769
              L13.0909091,15.4023769 L13.0909091,17.6027165 Z M13.0909091,13.2020374
              L10.9090909,13.2020374 L10.9090909,8.80135823 L13.0909091,8.80135823 L13.0909091,13.2020374 Z
            `"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
